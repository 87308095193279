import type { GetStaticPropsContext } from 'next'
import commerce from '@lib/api/commerce'
import Link from 'next/link'
import Image from 'next/image'
import { Breadcrumbs, Layout } from '@components/common'
import { HoskingsButton } from '@components/ui'
import getGlobalStaticProps from '@lib/get-global-static-props'
import { createClient } from '@lib/prismicio'
import { AnalyticsPageType } from '@shopify/hydrogen-react'
import ArticlesGrid from '@components/blog/ArticlesGrid'
import { PrismicDocument } from '@prismicio/types'
import { Blog } from '@commerce/types/blog'

export async function getStaticProps({
  preview,
  previewData,
  locale,
  locales,
}: GetStaticPropsContext) {
  const config = { locale, locales }
  const client = createClient({ previewData })
  const globalProps = await getGlobalStaticProps(client, locale)
  let blog = null

  try {
    const { blog: blogData } = await commerce.getBlogByHandle({
      variables: {
        handle: 'news',
      },
      config,
    })
    blog = blogData
  } catch (error) {
    console.error('failed to fetch blog:', error)
  }

  return {
    props: {
      ...globalProps,
      analyticsPayload: {
        pageType: AnalyticsPageType.notFound,
      },
      blog,
    },
    revalidate: 200,
  }
}

type PageProps = {
  metaValues: {
    title: string
    description: string
  }
  prismicDoc: PrismicDocument | null
  blog: Blog | null
}
export default function NotFound({ blog }: PageProps) {
  const suggestedPages = [
    {
      href: '/collections/jewellery',
      text: 'Jewellery',
      imgSrc: '/assets/jewellery.svg',
    },
    {
      href: '/collections/diamonds',
      text: 'Diamonds & Bridal',
      imgSrc: '/assets/diamondsAndBridal.svg',
    },
    {
      href: '/collections/all-watches',
      text: 'Watches',
      imgSrc: '/assets/watches.svg',
    },
  ]

  const suggestedCollections = [
    { href: '/collections/bracelets', text: 'Bracelets' },
    { href: '/collections/chains', text: 'Chains' },
    { href: '/collections/earrings', text: 'Earrings' },
    { href: '/collections/mens-jewellery', text: "Men's Range" },
    { href: '/collections/necklaces-necklets', text: 'Necklaces & Necklets' },
    { href: '/collections/charms', text: 'Charms' },
    { href: '/collections/rings', text: 'Rings' },
    { href: '/collections/baby', text: 'Childrens' },
  ]

  const linkPages = (href: string, text: string, imgSrc: string) => {
    return (
      <Link href={href} legacyBehavior>
        <div className="cursor-pointer">
          <Image
            src={imgSrc}
            alt={text}
            width={304}
            height={396}
            className="w-full h-auto"
          />
          <h3 className="text-gray-700 font-bold mt-4 uppercase">{text}</h3>
        </div>
      </Link>
    )
  }

  const linkCollections = (href: string, text: string) => {
    return (
      <Link href={href} legacyBehavior>
        <HoskingsButton size="medium" variant="secondary">
          {text}
        </HoskingsButton>
      </Link>
    )
  }

  const articles =
    blog && Array.isArray(blog?.articles) && blog.articles.length
      ? blog.articles.reverse().slice(0, 4)
      : []

  return (
    <div className="wrapper fit">
      <Breadcrumbs current="404 Page" />
      <div className="py-20 flex flex-col items-center justify-center">
        <div className="wrapper">
          <div className="text-mobileh3 lg:text-h2 text-text-dark text-center uppercase">
            Page Not Found
          </div>
          <div className="text-body1 text-text-subdued pt-4 pb-8 text-center">
            We're sorry, but the page you're looking for could not be found
          </div>
          <div className="flex justify-center mb-12">
            <Link href={'/'}>
              <HoskingsButton size="medium" variant="primary">
                Go to homepage
              </HoskingsButton>
            </Link>
          </div>
          <div className="text-h4 text-text-subdued pb-8 text-center">
            Or, browse our popular categories
          </div>
          <div className="suggested-pages w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-between mb-16 gap-8">
            {suggestedPages.map((page) =>
              linkPages(page.href, page.text, page.imgSrc)
            )}
          </div>
          <div className="text-mobileh3 text-h4 text-text-dark text-center pb-8">
            Other jewellery collections that might interest you
          </div>
          <div className="suggested-collections w-full grid grid-cols-2 md:grid-cols-4 gap-4">
            {suggestedCollections.map((collection) =>
              linkCollections(collection.href, collection.text)
            )}
          </div>
        </div>
        {articles.length ? (
          <div className="latest-articles w-full flex justify-around">
            <ArticlesGrid
              title={'Journal'}
              articles={articles}
              hideWrapper={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

NotFound.Layout = Layout
