import Link from 'next/link'
import { Fragment } from 'react'
import { BreadcrumItem } from 'types/Common'
import { getStoreFrontUrl, capitalizeLetters } from '@lib/helpers/general'
import { useRouter } from 'next/router'

interface Breadcrumbs {
  current: string
  options?: BreadcrumItem[]
}

const Breadcrumbs = ({ current, options }: Breadcrumbs) => {
  const { asPath } = useRouter()
  const currentBreadcrumbLink = asPath.split('?')[0]
  const strorefrontUrl = getStoreFrontUrl()

  if (!current) return <></>
  return <>
    <ul className="flex text-13 leading-4 pt-6 mb-6">
      <li
        className="text-text-disabled leading-4 mr-1 hover:underline z-10 cursor-pointer"
        key="home"
      >
        <Link href="/">Home</Link>
      </li>
      <li className="mx-2" key="home-divider">
        /
      </li>
      {options && options.length > 0 && 
        options.map((item, index) => {
          if (!item?.link) return ''
          return (
            <Fragment key={`options-${index}`}>
              <li className="leading-4 mr-1 text-text-disabled  hover:underline capitalize ">
                <Link href={item.link} legacyBehavior>{item.label}</Link>
              </li>
              <li className="mx-2">/</li>
            </Fragment>
          );
        })}
      <li
        className="ml-2 font-medium text-text-subdued capitalize"
        key="current"
      >
        <label>{current}</label>
      </li>
    </ul>
    {
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": [
                      {
                          "@type": "ListItem",
                          "position": "1",
                          "name": "Hoskings Jewellers",
                          "item": "${strorefrontUrl}/"
                      },
                      ${
                        options
                          ? options.map((option, index) => {
                              return ` {
                                  "@type": "ListItem",
                                  "position": "${index + 2}",
                                  "name": "${option.label}",
                                  "item": "${strorefrontUrl}${option.link}"
                               },`
                            })
                          : ''
                      }
                      {
                        "@type": "ListItem",
                        "position": "${options ? options.length + 2 : 2}",
                        "name": "${capitalizeLetters(current)}",
                        "item": "${strorefrontUrl}${currentBreadcrumbLink}"
                    }
                  ]
              }`,
        }}
      />
    }
  </>;
}

export default Breadcrumbs
